@charset "utf-8";

.navbar-default{
  background: #192332 !important;
  border: none !important;
}

.navbar-default .navbar-nav > li > a{
    color:#abc0ca !important;
}
.navbar-default .navbar-nav > li > a:hover{
    color:#404B5D !important;
}
.navbar-default .navbar-nav > li > a:focus{
    color:#404B5D !important;
}
.navbar-default .navbar-brand{
  color:#abc0ca !important;
}
body{
  background-color: #070F1B !important;
  font-family: "Fira Mono" !important;
  color: #abc0ca !important;
}
.page-stuff{
  a {
    color: #404B5D !important;
    text-decoration: none;
      &:hover{
        color:#404B5D !important;
        text-decoration: none !important;
        border-bottom: 1px dotted #404B5D;
        padding-bottom:0.5em;
      }
    }
  }
#page-content{
  padding-bottom: 4em;
}

.page-stuff{
  padding-top: 2em;
  padding-bottom: 5em;
}
.jumbotron {
  background: #192332 !important;
}
a {
    color: #404B5D;
    text-decoration: none;
}
a:hover{
  color:#404B5D !important;
  text-decoration: none !important;
  border-bottom: 1px dotted #404B5D;

}

.post-list{
    
}
.post-box{
  padding-top: 2em;
  padding-bottom: 2em;
}

.post-title{
  font-size: 30px !important;
  color:  #abc0ca !important;
}

.post-excerpt{
  color: white !important;
  font-size: 15px;
  text-align: justify;
padding-top: 0.5em;
padding-bottom: 0.3em;
}
.post-title-main{
  text-align: center;
  color: #abc0ca !important;
}
.post-content{
  font-size: 19px;
  text-align: justify;
  padding-bottom: 5em;
}
pre{
background-color: black !important;
border:none !important;
border-radius: 0px !important;
font-size: 15px!important;
padding: 1.5em !important;
color:white !important;
}
.post-meta{
  color: skyblue !important;
}

code{
  background-color: black !important;
  color: white !important;
  border: none !important;
  border-radius: 0px !important;
}

.page-tagline{
  font-size: 20px;
text-align: center;
}

.download{
  padding:2em;
  font-size: 19px;
}
.intro{
  font-size: 19px;

}
.manual-title{
  font-size: 21px;
  padding: 1em;
  text-transform: uppercase;

}
.manual-content{
  text-align: justify;
  font-size: 19px;
}

.man-title{
  font-size: 22px;
  text-align: center;
  padding: 1em;
  letter-spacing: 2px;

}

.jumbotron{
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
}
.navbar{
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
}
// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;


.thumbnail {
  max-width: 30%;

}

img {
  background-color: #070f1b !important;
  border: 1px solid #304360 !important;
  padding: 0px !important;
}

/** LIGHTBOX MARKUP **/

.lightbox {
	/** Default lightbox to hidden */
	display: none;

	/** Position and style */
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.8);
}

.lightbox img {
	/** Pad the lightbox image */
	max-width: 90%;
	max-height: 80%;
	margin-top: 2%;
}

.lightbox:target {
	/** Remove default browser outline */
	outline: none;

	/** Unhide lightbox **/
	display: block;
}



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "syntax-highlighting"
;
